import Model from '@/util/Model'
// import _c from '../common-constants'

export class Profile extends Model {
  constructor (data = {}) {
    const fields = [
      {
        name: 'first',
        type: 'string',
        required: true
      },
      {
        name: 'last',
        type: 'string',
        required: true
      },
      {
        name: 'avatar',
        type: 'string'
      },
      {
        name: 'birthday',
        type: 'date'
      },
      {
        name: 'title',
        type: 'string'
      },
      {
        name: 'email',
        type: 'string',
        required: true
      },
      {
        name: 'phone',
        type: 'string',
        required: true
      },
      {
        name: 'lang',
        type: 'string',
        default: 'en'
      },
      {
        name: 'locale',
        type: 'string'
      },
      {
        name: 'addresses',
        type: 'array',
        default: []
      },
      {
        name: 'emails',
        type: 'array',
        default: []
      },
      {
        name: 'phones',
        type: 'array',
        default: []
      },
      {
        name: 'location',
        type: 'array',
        idOnly: true
      },
      {
        name: 'shipping',
        type: 'object',
        idOnly: true
      },
      {
        name: 'billing',
        type: 'object',
        idOnly: true
      },
      {
        name: 'shoppingcarts',
        type: 'object',
        default: {}
      },
      {
        name: 'cart',
        type: 'object',
        default: { items: {}, details: {} }
      },
      {
        name: 'preorders',
        type: 'object',
        default: { items: {}, details: {} }
      },
      {
        name: 'customorders',
        type: 'object',
        default: { items: {}, details: {} }
      },
      {
        name: 'extradata',
        type: 'object',
        default: {}
      },
      {
        name: 'settings',
        type: 'object'
      }
    ]
    super({ fields: fields }, data)
  }
}

export class Address extends Model {
  constructor (data = {}) {
    const fields = [
      {
        name: 'aid',
        type: 'string'
      },
      {
        name: 'type',
        type: 'string'
      },
      {
        name: 'country',
        type: 'string',
        required: true,
        default: 'ca'
      },
      {
        // name: 'first',
        // type: 'string',
        // }, {
        // name: 'last',
        // type: 'string',
        // }, {
        name: 'prov',
        type: 'string',
        required: true
      },
      {
        name: 'city',
        type: 'string',
        required: true
      },
      {
        name: 'street',
        type: 'string',
        required: true
      },
      {
        name: 'apt',
        type: 'string'
      },
      {
        name: 'code',
        type: 'string',
        required: true
      },
      {
        name: 'phone',
        type: 'string',
        required: true
      },
      {
        name: 'email',
        type: 'string',
        required: true
      }
    ]
    super({ fields: fields }, data)
  }
}
export class Phone extends Model {
  constructor (data = {}) {
    const fields = [
      {
        name: 'type',
        type: 'string'
      },
      {
        // name: 'areacode',
        // type: 'string',
        // maxLength: 3,
        // required: true,
        // }, {
        name: 'number',
        type: 'string',
        required: true
      },
      {
        name: 'extension',
        type: 'string'
      }
    ]
    super({ fields: fields }, data)
  }
}
export class Email extends Model {
  constructor (data = {}) {
    const fields = [
      {
        name: 'type',
        type: 'string'
      },
      {
        name: 'address',
        type: 'string',
        required: true,
        match: /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i
      }
    ]
    super({ fields: fields }, data)
  }
}
